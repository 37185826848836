import React from "react";
import { foodImages1, foodImages2, foodImages3, foodImages4 } from "../assets";

const Foods = () => {
  return (
    <div className="bg-[#24014A] md:p-[80px] py-10 px-4 text-white">
      <h3 className="font-black md:text-4xl text-2xl mb-4">Our Foods & Drinks.</h3>
      <p className="font-raleway font-semibold mb-10 md:text-base text-xs">
        Range of varieties to pick from..
      </p>
      <div className="flex md:flex-row flex-col w-full gap-10">
        <img src={foodImages1} alt="" className="md:w-[250px]" />
        <img src={foodImages2} alt="" className="md:w-[250px]" />
        <img src={foodImages3} alt="" className="md:w-[250px]" />
        <img src={foodImages4} alt="" className="md:w-[250px]" />
      </div>
    </div>
  );
};

export default Foods;
