import React from "react";
import { gameImages1 } from "../assets";
import { games2 } from "../constants";
import BookNow from "./buttons/BookNow";

const Games2 = () => {
  return (
    <div className="md:p-[80px] py-10 px-4 flex flex-col gap-10">
      {games2.map((item) => (
        <div className="text-white">
          <div className="md:h-[400px] h-[180px] w-full object-cover">
            <img src={item.imagePicture} alt="" className="md:h-[400px] h-[180px] w-full" />
          </div>
          <div className="md:p-10 py-6 px-4 bg-[#24014A]">
            <div className="flex items-end gap-4 mb-4">
              <h3 className="font-black md:text-4xl text-2xl">{item.hText}</h3>
              {item?.cs && (
                <p className="font-semibold font-raleway md:text-base text-sm">
                  Coming soon
                </p>
              )}
            </div>
            <p className="font-raleway font-semibold mb-6 md:w-[68%]">
              {item.pText}
            </p>
            <div className="flex gap-4 font-raleway font-semibold md:w-[70%] flex-wrap mb-10">
              {item.requirements.map((req) => (
                <div className="rounded-full border border-white md:px-6 px-4 md:text-base text-sm py-1">
                  {req.text}
                </div>
              ))}
            </div>
            <BookNow />
            {/* <a
                href="https://bookedit.online/activate-vr-peterborough/"
                target="_blank"
                rel="noreferrer"
                className="bg-custom-gradient py-2 px-6 rounded-full text-white font-bold md:text-base text-sm"
              >
                BOOK NOW
              </a> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Games2;
