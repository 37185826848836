import { experienceImage1, experienceImage2, experienceImage3, experienceImage4, experienceImage5, experienceImage6, experienceImage7, gameImage1, gameImage10, gameImage11, gameImage12, gameImage13, gameImage14, gameImage15, gameImage16, gameImage17, gameImage18, gameImage19, gameImage2, gameImage20, gameImage3, gameImage4, gameImage5, gameImage6, gameImage7, gameImage8, gameImage9, gameImages1, gameImages2, gameImages3, gameImages4, gameImages5, gameImages6, gameImages7, sliderImage, sliderImage10, sliderImage2, sliderImage3, sliderImage30, sliderImage4, sliderImage5, sliderImage6, sliderImage7, sliderImage8, sliderImage9 } from "../assets";

export const navLinks = [
  {
    id: 1,
    linkName: 'Home',
    linkRoute: "/",
  },
  {
    id: 2,
    linkName: 'Games & Activities',
    linkRoute: "/games-and-activities",
  },
  {
    id: 3,
    linkName: 'Parties & Events',
    linkRoute: "/parties-and-events",
  },
  {
    id: 4,
    linkName: 'Foods & Drinks',
    linkRoute: "/foods-and-drinks",
  },
  {
    id: 5,
    linkName: 'Vouchers',
    linkRoute: "/vouchers",
  },
  {
    id: 6,
    linkName: 'Contact Us',
    linkRoute: "/contact-us",
  },
];

export const experiences = [
  {
    id: 1,
    hText: 'VR Arcade',
    pText: "Take part in the fast-paced excitement of over 50+ Arcade Game collections.",
    imagePicture: experienceImage2,
  },
  {
    id: 2,
    hText: 'VR Escape Rooms',
    pText: "Test yourself with our collection of 13 VR Escape Rooms, each offering a unique storyline",
    imagePicture: gameImages1,
  },
  {
    id: 3,
    hText: 'VR Free Roam',
    pText: "Our arcade free-roam VR lets you explore jaw-dropping virtual worlds without limits",
    imagePicture: experienceImage3,
  },
  {
    id: 6,
    hText: 'E-Dart',
    pText: "Thrilling Fun, Precision, and Competition in Every Throw!",
    imagePicture: experienceImage5,
    // cs:true
  },
  {
    id: 4,
    hText: 'Party Room',
    pText: "Experience the thrill and action with our wall climbing experience",
    imagePicture: experienceImage6,
  },
  {
    id: 5,
    hText: 'Climbing Walls',
    pText: "Experience the thrill and action with our wall climbing experience",
    imagePicture: experienceImage4,
    // cs:true
  },
  {
    id: 7,
    hText: 'Bowling',
    pText: "Our state-of-the-art lanes offers a fun and exciting experience for all ages.",
    imagePicture: experienceImage7,
    cs:true
  },
  
];

export const games = [
  {
    id: 1,
    pText: "Creed: Rise To Glory",
    linkUrl: "https://youtu.be/ioWvWaNa2VA",
    imagePicture: gameImage1,
  },
  {
    id: 2,
    pText: "All In One Sport VR",
    linkUrl: "https://youtu.be/4kn22u-aoNs",
    imagePicture: gameImage2,
  }, 
  {
    id: 3,
    pText: "Job Simulator",
    linkUrl: "https://youtu.be/-H_NcD6T9qc",
    imagePicture: gameImage3,
  }, 
  {
    id: 4,
    pText: "Crisis Vrigade 2",
    linkUrl: "https://youtu.be/rzIgjpIV7Sk",
    imagePicture: gameImage4,
  }, 
  {
    id: 5,
    pText: "The Prison",
    linkUrl: "https://youtu.be/OY4Poje0I0U",
    imagePicture: gameImage5,
  }, 
  {
    id: 6,
    pText: "Mission Sigma",
    linkUrl: "https://youtu.be/vJc4NdqcAm4",
    imagePicture: gameImage6,
  }, 
  {
    id: 7,
    pText: "Chernobyl",
    linkUrl: "https://youtu.be/DEjcbxhgL3k",
    imagePicture: gameImage7,
  }, 
  {
    id: 8,
    pText: "Dead Ahead",
    linkUrl: "https://youtu.be/rwj5IUWqhJw",
    imagePicture: gameImage8,
  }, 
  // {
  //   id: 9,
  //   imagePicture: gameImage9,
  // }, 
  // {
  //   id: 10,
  //   imagePicture: gameImage10,
  // }, 
  // {
  //   id: 11,
  //   imagePicture: gameImage11,
  // }, 
  // {
  //   id: 12,
  //   imagePicture: gameImage12,
  // }, 
  // {
  //   id: 13,
  //   imagePicture: gameImage13,
  // }, 
  // {
  //   id: 14,
  //   imagePicture: gameImage14,
  // }, 
  // {
  //   id: 15,
  //   imagePicture: gameImage15,
  // }, 
  // {
  //   id: 16,
  //   imagePicture: gameImage16,
  // }, 
  // {
  //   id: 17,
  //   imagePicture: gameImage17,
  // }, 
  // {
  //   id: 18,
  //   imagePicture: gameImage18,
  // }, 
  // {
  //   id: 19,
  //   imagePicture: gameImage19,
  // }, 
  // {
  //   id: 20,
  //   imagePicture: gameImage20,
  // }, 
];

export const games2 = [
  {
    id: 1,
    hText: "VR Arcade",
    pText: "Take part in the fast-paced excitement of our Arcade game collections, which features over 50 different games. For instance, you can try out our job simulator game, where you can work as different occupations of your choice, or unleash your culinary skills and cook up a storm in the chaotic environment of ‘Cook’d Up.’",
    requirements: [
      {
        id: 1,
        text: "AGE 7+",
      },
      {
        id: 2,
        text: "1-10 PLAYERS",
      },
      {
        id: 3,
        text: "30-90 MINUTES",
      },
      {
        id: 4,
        text: "PARTIES",
      },
      {
        id: 5,
        text: "KIDS/FAMILY/GROUP",
      },
      {
        id: 6,
        text: "SINGLE PLAYER",
      },
      {
        id: 7,
        text: "MULTIPLAYER",
      },
    ],
    imagePicture: experienceImage2,
  },
  {
    id: 2,
    hText: "VR Escape​ Rooms",
    pText: "Test yourself with our collection of over 13 VR Escape Rooms, each offering a unique storyline. For instance, you and your friends can work on preventing galactic space station failures or find yourself stranded on an island together where survival skills are essential.",
    requirements: [
      {
        id: 1,
        text: "AGE 7+",
      },
      {
        id: 2,
        text: "2-6 PLAYERS",
      },
      {
        id: 3,
        text: "60 MINUTES",
      },
      {
        id: 4,
        text: "KIDS/FAMILY/GROUPS",
      },
      {
        id: 5,
        text: "PUZZLE",
      },
      {
        id: 6,
        text: "PARTIES",
      },
    ],
    imagePicture: experienceImage1,
  },
  {
    id: 3,
    hText: "VR Free Roam",
    pText: "Our arcade free-roam VR lets you explore jaw-dropping virtual worlds without limits - no wires, no boundaries, just pure adrenaline. Team up with friends or go solo as you dodge, shoot, and strategise in immersive games that react to your every move. Ready to play in a whole new way?",
    requirements: [
      {
        id: 1,
        text: "AGE 10+",
      },
      {
        id: 2,
        text: "2-4 PLAYERS",
      },
      {
        id: 3,
        text: "30-60 MINUTES",
      },
      {
        id: 4,
        text: "ZOMBIES",
      },
      {
        id: 5,
        text: "SHOOTER",
      },
    ],
    imagePicture: gameImages3,
  },
  {
    id: 6,
    hText: "E-Dart",
    pText: "Our Interactive Dart activity offers an interactive experience with real-time scoring, immersive lighting effects, and exciting game modes to keep the competition alive! Perfect for friendly matches or solo practice. Grab your friends, aim for the bullseye, and experience the thrill of interactive dart today!",
    requirements: [
      {
        id: 1,
        text: "ADULTS/GROUPS",
      },
      {
        id: 2,
        text: "MULTIPLE GAMES",
      },
      {
        id: 3,
        text: "60 MINUTES",
      },
      {
        id: 4,
        text: "SINGLE PLAYER",
      },
      {
        id: 5,
        text: "MULTIPLAYER",
      },
      {
        id: 6,
        text: "PARTIES",
      },
      {
        id: 7,
        text: "AGE 10+",
      },
    ],
    imagePicture: gameImages5,
    // cs:true
  }, 
  {
    id: 4,
    hText: "Party Room",
    pText: "Looking to host an event or enjoy a birthday party with us that all your guests will remember? We host a variety of events, including staff parties, corporate gatherings, and any celebration you have in mind!",
    requirements: [
      {
        id: 1,
        text: "60 MINUTES",
      },
      {
        id: 2,
        text: "FOR EVERYONE",
      },
      {
        id: 3,
        text: "PARTIES & EVENTS",
      },
    ],
    imagePicture: gameImages6,
  },
  {
    id: 5,
    hText: "Climbing Walls",
    pText: "Are you a beginner or a seasoned climber, our walls offer routes for all skill levels; complete with expert guidance and top-tier safety features. Perfect for fitness, fun and team-building. Are you ready to climb? Come conquer the wall and see how high you can go!",
    requirements: [
      {
        id: 1,
        text: "AGE 4+",
      },
      {
        id: 2,
        text: "1-10 PLAYERS",
      },
      {
        id: 3,
        text: "60 MINUTES",
      },
      {
        id: 4,
        text: "ADULTS",
      },
      {
        id: 5,
        text: "KIDS/FAMILY/GROUPS",
      },
      {
        id: 6,
        text: "PARTIES",
      },
    ],
    imagePicture: experienceImage4,
    // cs:true
  },
  {
    id: 7,
    hText: "Bowling",
    pText: "Ready to strike? Come Discover the thrill of bowling at ACTIV8 VR! Perfect for family outings, friendly competitions, or date nights, our state-of-the-art lanes offers a fun and exciting experience for all ages. With bumpers for beginners and challenges for pros, everyone's invited to join the game, rack up points, and enjoy the classic excitement of bowling.",
    requirements: [
      {
        id: 2,
        text: "CHILDREN/ADULTS",
      },
      {
        id: 3,
        text: "PARTIES",
      },
      {
        id: 4,
        text: "60 MINUTES",
      },
    ],
    imagePicture: gameImages7,
    cs:true,
  }, 
];

export const testimonials = [
  {
    id: 1,
    name: "Petra Stoklasova",
    quote: "They have the best service and great quality games. You can have a lot of fun with friends or awesome parties.🎉",
    jobTitle: "CEO, Company A"
  },
  {
    id: 2,
    name: "Jane Smith",
    quote: "If you haven't been here then you're missing out on so many fun games and activities",
    jobTitle: "CEO, Company A"
  },
  {
    id: 3,
    name: "Jemma Irvine",
    quote: "Took my youngest son and a few of his friends for a 1hr session. They had a great time and loved it. The staff were very friendly and attentive. We will definitely be back.",
    jobTitle: "CEO, Company A"
  },
  {
    id: 4,
    name: "Diane Bailes",
    quote: "A brilliant experience for my 12 year old grandson. He thoroughly enjoyed himself in a safe & caring environment.",
    jobTitle: "CEO, Company A"
  },
  {
    id: 5,
    name: "Benjamin Ayling",
    quote: "Awesome experience, did the operation sigma escape room, would highly recommend.",
    jobTitle: "CEO, Company A"
  },
];

export const sliderImages = [
  {
    id: 1,
    imagePicture: sliderImage,
  },
  {
    id: 2,
    imagePicture: sliderImage2,
  },
  {
    id: 6,
    imagePicture: sliderImage6,
  },
  {
    id: 7,
    imagePicture: sliderImage7,
  },
  {
    id: 8,
    imagePicture: sliderImage8,
  },
  {
    id: 9,
    imagePicture: sliderImage9,
  },
  {
    id: 10,
    imagePicture: sliderImage10,
  },
  {
    id: 4,
    imagePicture: sliderImage4,
  },
  {
    id: 5,
    imagePicture: sliderImage5,
  },
  {
    id: 3,
    imagePicture: sliderImage30,
  },
];