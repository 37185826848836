import React, { useState } from 'react'
import { nextIcon, previousIcon } from '../assets'
import { sliderImages } from '../constants';

const SliderImages = () => {
  
  const [currentIndex, setCurrentIndex] = useState(0);

  const styles = {
    backgroundImage: `url(${sliderImages[currentIndex].imagePicture})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  };

  // Functions to handle next and previous buttons
  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderImages.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <div
        style={styles}
        className="md:h-screen h-[300px] flex justify-between items-center"
      >
        <div 
          className="bg-[#7F00B3] w-10 h-[150px] flex items-center justify-center cursor-pointer"
          onClick={prevTestimonial}
        >
          <img src={previousIcon} alt="" className="w-4 h-4" />
        </div>
        <div 
          className="bg-[#7F00B3] w-10 h-[150px] flex items-center justify-center cursor-pointer"
          onClick={nextTestimonial}
        >
          <img src={nextIcon} alt="" className="w-4 h-4" />
        </div>
      </div>
  )
}

export default SliderImages