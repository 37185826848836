import React from "react";
import { bookingImage } from "../assets";
import BookNow from "./buttons/BookNow";

const Location = () => {
  return (
    <div className="bg-[#24014A] text-white md:p-[80px] px-4 py-10 flex md:flex-row flex-col md:items-center md:gap-0 gap-10">
      <img src={bookingImage} alt="" className="md:w-[45%]" />
      <div className="w-full">
        <div className=" flex md:flex-row flex-col md:gap-0 gap-4 justify-between md:items-center md:pl-10 mb-6">
          {/* <h4 className="font-semibold">
            Peterborough, <br />
            United Kingdom
          </h4> */}
          <h4 className="font-semibold font-raleway">
            Unit 3, Serpentine Green shopping centre <br />
            Peterborough, PE7 8BE
          </h4>
          <div className="">
            <BookNow />
          </div>
        </div>
        <div className="md:pl-10 mb-4">
          <h4 className="font-raleway font-semibold underline mb-4">
            Term Time
          </h4>
          <div className="flex gap-10 items-center">
            <div className="flex flex-col gap-2">
              <h4 className="font-raleway font-semibold">
                Mon:
                <span className="font-normal"> Closed</span>
              </h4>
              <h4 className="font-raleway font-semibold">
                Sat: <span className="font-normal"> 10:00 - 20:00</span>
              </h4>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="font-raleway font-semibold">
                Tue - Friday:
                <span className="font-normal"> 12:00 - 20:00</span>
              </h4>
              <h4 className="font-raleway font-semibold">
                Sun: <span className="font-normal"> 10:00 - 16:00</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="md:pl-10">
          <h4 className="font-raleway font-semibold underline mb-4">Holiday</h4>
          <div className="flex gap-10 items-center">
            <div className="flex flex-col gap-2">
              <h4 className="font-raleway font-semibold">
                Mon - Sat:
                <span className="font-normal"> 10:00 - 20:00</span>
              </h4>
              <h4 className="font-raleway font-semibold">
                Sun:
                <span className="font-normal"> 10:00 - 16:00</span>
              </h4>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="font-raleway font-semibold">
                Phone:
                <span className="font-normal"> 01733798050</span>
              </h4>
              <h4 className="font-raleway font-semibold">
                Email:
                <span className="font-normal"> play@activ8vr.com</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
