import React from "react";
import { experiences } from "../constants";
import { vrIcon } from "../assets";
import { Link } from "react-router-dom";

const Games = () => {
  return (
    <div className="bg-[#24014A] md:p-[80px] py-10 px-4 text-white">
      <div className="flex md:flex-row flex-col md:justify-between items-center mb-10 md:text-left text-center">
        <div className="">
          <h3 className="font-black md:text-4xl text-xl md:mb-4 mb-2">
            Games & Activities
          </h3>
          <p className="font-raleway font-semibold md:text-base text-xs md:mb-0 mb-4">
            There is something for everyone..
          </p>
        </div>
        <Link
          to="/games-and-activities"
          className="bg-custom-gradient py-2 px-6 md:text-base text-xs rounded-full text-white font-bold"
        >
          VIEW ALL ACTIVITIES
        </Link>
      </div>

      <div className="grid md:grid-cols-2 gap-10">
        {experiences.map((item) => (
          <div className="relative md:h-[320px] w-full">
            {/* <img
              src={vrIcon}
              alt=""
              className="absolute md:top-10 md:left-10 top-4 left-4 h-6"
            /> */}
            <img src={item.imagePicture} alt="" className="md:h-[320px] w-full" />
            <div className="absolute md:bottom-10 md:left-10 bottom-4 left-4">
              <div className="flex items-end gap-4 mb-4">
                <h4 className="font-black text-xl">{item.hText}</h4>
                {item?.cs && (
                  <p className="font-semibold font-raleway md:text-sm text-xs">
                    Coming soon
                  </p>
                )}
              </div>
              <p className="font-raleway font-semibold w-[80%]">{item.pText}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Games;
