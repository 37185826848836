import React from 'react'
import { contactHeroBg } from '../assets';
import Header from '../components/Header';
import Events from '../components/Events';
import Questions from '../components/Questions';
import Location from '../components/Location';

const ContactPage = () => {

  return (
    <div>
      <Header 
        hText='Contact & Locations.' 
        pText="We're available to respond to your enquiries and feedback. Reach us via email, call lines or even visit our location, we are always happy to help." 
        imagePicture={contactHeroBg} 
      />
      <Questions />
      <Location />
      <Events />
    </div>
  )
}

export default ContactPage