import React from "react";
import { Link } from "react-router-dom";

const BookGames = () => {
  return (
    <a
      href="https://go.funl.ink/J5XMkfn0"
      // target="_blank"
      rel="noreferrer"
      className="bg-transparent border border-1 border-white py-2 px-6 rounded-full text-white font-bold md:text-base text-sm"
    >
      BOOK GAMES
    </a>
  );
};

export default BookGames;
