import React, { useState, useEffect } from "react";
import NewHeroSection from "../components/NewHeroSection";
import ClickHere from "../components/ClickHere";
import Games from "../components/Games";
import Events from "../components/Events";
import VrGames from "../components/VrGames";
import Location2 from "../components/Location2";
import Testimonials from "../components/Testimonials";
import SliderImages from "../components/SliderImages";
import { closeModalIcon } from "../assets";

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Set a timer to show the modal after 10 seconds
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 1000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => setShowModal(false);

  return (
    <div>
      <NewHeroSection />
      <ClickHere />
      <SliderImages />
      <Games />
      <Events />
      <VrGames />
      <Location2 />

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 font-raleway">
          <div className="bg-white p-6 shadow-md md:w-[550px] rounded-2xl text-center flex flex-col items-center text-[#34004A] mx-4">
            <div className="flex justify-end mb-6 w-full">
              <img src={closeModalIcon} alt="" className="cursor-pointer" onClick={closeModal} />
            </div>
            <h2 className="text-2xl font-bold mb-4">
              🎄 Celebrate Christmas with 20% Off! 🎄
            </h2>
            <p className="text-center mb-4">
              Book your climbing wall experience this holiday season and enjoy
              20% off all bookings! Offer valid for a limited time only – don’t
              miss out!
            </p>
            <p className="text-sm mb-6 font-bold">
              👉 Book now and climb into the festive spirit!
            </p>
            <div className="mb-10">
              <a
                href="https://go.funl.ink/J5XMkfn0"
                // target="_blank"
                rel="noreferrer"
                className="bg-custom-gradient py-2 px-6 rounded-full text-white font-bold md:text-base text-sm w-full text-center"
              >
                BOOK NOW
              </a>
            </div>
            <p className="text-sm text-[#C9C9C9]">Terms and conditions apply</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
