import React from 'react'
import { heroLogo } from '../assets'

const NewHeroSection = () => {
  return (
    <div className='bg-black h-screen flex justify-center items-center'>
      <img src={heroLogo} alt="" className="md:h-[300px] animate-scaleUpDown" />
    </div>
  )
}

export default NewHeroSection