import React from 'react'
import { planningImage, vouchersHeroBg, vouchersImage } from '../assets'
import Header from '../components/Header'
import Events from '../components/Events'
import Planning from '../components/Planning'

const VouchersPage = () => {
  return (
    <div>
      <Header
        hText="Voucher Deals & Packages"
        pText="Looking for the perfect Voucher gift? Treat friends and family to an unforgettable VR experience with VRXtra. Ideal for any occasion, from parties to first-time visits!"
        imagePicture={vouchersHeroBg}
      />
      <Planning
        hText="Purchase Vouchers to be used anytime"
        pText="At checkout, you’ll see suggested amounts to help you decide based on the number of people you want to accommodate. Or you can enter your own amount."
        bText="BUY VOUCHER"
        voucher={true}
        imagePicture={vouchersImage}
      />
      <Events />
    </div>
  )
}

export default VouchersPage