import './App.css';
import { Routes, Route } from 'react-router-dom'
import { Navbar, Footer } from './components';
import { HomePage } from './pages';
import NewNavbar from './components/NewNavbar';
import GamesPage from './pages/GamesPage';
import PrivacyPage from './pages/PrivacyPage';
import OldHomePage from './pages/OldHomePage';
import TermsPage from './pages/TermsPage';
import ContactPage from './pages/ContactPage';
import PartiesPage from './pages/PartiesPage';
import FoodsPage from './pages/FoodsPage';
import VouchersPage from './pages/VouchersPage';
import ScrollToTop from './hoc/ScrollToTop';
import BookingPage from './pages/BookingPage';
import PartyBookingPage from './pages/PartyBookingPage';

function App() {
  return (
    <div className="font-orbitron">
      {/* <Navbar /> */}
      <NewNavbar />
      <ScrollToTop>
        <Routes>
          {/* <Route path='/' element={ <OldHomePage /> } /> */}
          <Route path='/' element={ <HomePage /> } />
          <Route path='/games-and-activities' element={ <GamesPage /> } />
          <Route path='/parties-and-events' element={ <PartiesPage /> } />
          <Route path='/foods-and-drinks' element={ <FoodsPage /> } />
          <Route path='/vouchers' element={ <VouchersPage /> } />
          <Route path='/contact-us' element={ <ContactPage /> } />
          <Route path='/privacy-policies' element={ <PrivacyPage /> } />
          <Route path='/terms-and-conditions' element={ <TermsPage /> } />
          {/* <Route path='/booking' element={ <BookingPage /> } /> */}
          {/* <Route path='/booking/parties' element={ <PartyBookingPage /> } /> */}
        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
