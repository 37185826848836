import React from "react";
import {
  facebookIcon,
  instagramIcon,
  tiktokIcon,
  twitterIcon,
  youtubeIcon,
} from "../assets";
import { Link, NavLink } from "react-router-dom";
import { navLinks } from "../constants";

const Footer = () => {
  return (
    <div className="w-full">
      <a
        href="mailto:play@activ8vr.com?subject=Mail from Activ8 VR Website"
        className="flex justify-center text-center py-10 text-white bg-custom-gradient w-full cursor-pointer"
      >
        <p className="font-black md:text-base text-xs hover:animate-scaleUpDown">
          GOT ANY ENQUIRIES? CLICK HERE TO GET IN TOUCH
        </p>
      </a>
      <div className="bg-black py-[60px]">
        <div className="flex justify-center items-center gap-4 mb-10">
          <a
            href="https://www.facebook.com/profile.php?id=61567220398250&mibextid=LQQJ4d"
            target="_blank"
            rel="noreferrer"
            className=""
          >
            <img src={facebookIcon} alt="" className="w-10" />
          </a>
          <a
            href="https://www.instagram.com/activ8_vr?igsh=ODU5b2cxNzdxNHdz&utm_source=qr"
            target="_blank"
            rel="noreferrer"
            className=""
          >
            <img src={instagramIcon} alt="" className="w-10" />
          </a>
          <a
            href="https://www.tiktok.com/@activ8.vr?_t=8qWxDH8e1gH&_r=1"
            target="_blank"
            rel="noreferrer"
            className=""
          >
            <img src={tiktokIcon} alt="" className="w-10" />
          </a>
          {/* <a
            href="https://www.tiktok.com/@activ8.vr?_t=8qWxDH8e1gH&_r=1"
            target="_blank"
            rel="noreferrer"
            className=""
          >
            <img src={youtubeIcon} alt="" className="w-10" />
          </a> */}
        </div>
        <div className="flex md:flex-row flex-col justify-center items-center gap-10 text-[#6A6A6A] font-black">
          {navLinks.map((item) => (
            <NavLink
              to={item.linkRoute}
              className="hover:text-gray-300"
              style={({ isActive }) => {
                return {
                  color: isActive ? "#7F00B3" : "",
                };
              }}
            >
              {item.linkName}
            </NavLink>
          ))}
          {/* <p className="">Home</p>
          <p className="">Games & Experiences</p>
          <p className="">Parties & Events</p>
          <p className="">Foods & Drinks</p>
          <p className="">Vouchers</p>
          <p className="">Contact Us</p> */}
        </div>
      </div>
      <div className="flex md:flex-row flex-col justify-between items-center bg-[#2F1141] text-[#FFFFFF40] py-6 md:px-[80px] md:gap-0 gap-6 font-black">
        <div className="flex items-center gap-6">
          <Link to="/privacy-policies" className="">
            Privacy Policies
          </Link>
          <Link to="/terms-and-conditions" className="">
            Terms & Conditions
          </Link>
        </div>
        <p className="">Copyright 2024, Activ8 VR</p>
      </div>
    </div>
  );
};

export default Footer;
