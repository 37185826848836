import React from "react";
import {
  gamesHeroBg,
} from "../assets";
import Header from "../components/Header";
import Events from "../components/Events";
import ClickHere from "../components/ClickHere";
import Games2 from "../components/Games2";

const GamesPage = () => {

  return (
    <div>
      <Header
        hText="Games & Activites."
        pText="Find the ideal experience that suits you! With a collection of over 80 different experiences, we have an experience for everyone!"
        imagePicture={gamesHeroBg}
      />
      <ClickHere />
      <Games2 />
      <Events />
    </div>
  );
};

export default GamesPage;
