import React from "react";

const Questions = () => {
  return (
    <div className="flex flex-col items-center text-[#34004A] text-center py-16 md:px-0 px-4">
      <h1 className="font-black md:text-5xl text-2xl md:leading-tight mb-4 md:w-[60%]">
        GOT ANY QUESTIONS? REACH OUT TO US
      </h1>
      <p className="font-raleway md:mb-10 mb-6 font-semibold md:w-[50%]">
      Reach us via email, call lines or even visit our location, we are always happy to help.. Get in touch with us by clicking the button below.
      </p>
      <div className="flex gap-4 justify-center">
        <a
          href="mailto:play@activ8vr.com?subject=Mail from Activ8 VR Website"
          className="bg-custom-gradient py-2 px-6 rounded-full text-white font-bold md:text-base text-sm"
        >
          EMAIL US
        </a>
        <a
          href="tel:+01733798050"
          target="_blank"
          rel="noreferrer"
          className="bg-transparent py-2 px-6 rounded-full text-[#34004A] font-bold md:text-base text-sm border border-[#34004A]"
        >
          CALL US
        </a>
      </div>
    </div>
  );
};

export default Questions;
