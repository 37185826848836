import React from "react";
import { youtube } from "../assets";
import { games } from "../constants";

const VrGames = () => {
  return (
    <div className="bg-[#24014A] md:p-[80px] py-10 px-4 text-white">
      <div className="mb-10">
        <h3 className="font-black md:text-4xl text-xl md:mb-4 mb-2">
          Some of our 50+ VR Games
        </h3>
      </div>
      <div className="grid md:grid-cols-4 gap-10 mb-16">
        {games.map((item) => (
          <div className="flex flex-col gap-4">
            <div className="relative">
              <img src={item.imagePicture} alt="" className="" />
              <div className="absolute flex justify-center bottom-6 w-full">
                <a
                  href={item.linkUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-transparent border border-1 border-white md:py-2 py-3 md:px-6 px-16 rounded-full text-white font-bold md:text-[10px] text-sm flex items-center gap-2"
                >
                  <img src={youtube} alt="" className="" />
                  Watch Trailer
                </a>
              </div>
            </div>
            <p className="text-white font-raleway font-semibold">
              {item.pText}
            </p>
          </div>
        ))}
      </div>
      {/* <div className="flex justify-center">
        <a
          href=""
          target="_blank"
          rel="noreferrer"
          className="bg-custom-gradient py-2 px-6 rounded-full text-white font-bold md:text-base text-sm"
        >
          VIEW MORE
        </a>
      </div> */}
    </div>
  );
};

export default VrGames;
