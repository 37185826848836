import React from "react";
import { Link } from "react-router-dom";

const ClickHere = () => {
  return (
    <a 
      href="https://go.funl.ink/J5XMkfn0" 
      // target="_blank" 
      rel="noreferrer"
    >
      <div className="flex justify-center text-center py-10 text-white bg-custom-gradient w-full">
        <p className="font-black md:text-base text-xs hover:animate-scaleUpDown">
          CLICK HERE TO BOOK GAMES & ACTIVITIES
        </p>
      </div>
    </a>
  );
};

export default ClickHere;
