import React from "react";
import { locationImage } from "../assets";

const Location2 = () => {
  return (
    <div className="flex md:flex-row flex-col text-white">
      <div className="md:w-[45%] bg-[#0D0512]  pt-10">
        <h3 className="font-black md:text-4xl text-2xl text-white mb-10 md:text-left text-center md:pl-[80px]">
          Our Location
        </h3>
        <div className="bg-[#34004A] flex">
          <div className="bg-[#7F00B3] w-6 md:h-[380px] h-[450px]"></div>
          <div className="w-full flex flex-col justify-center">
            <div className=" flex flex-col md:flex-row md:gap-10 gap-4 md:justify-start justify-center md:items-center md:pl-10 pl-4 mb-6">
              {/* <h4 className="font-semibold">
                  Peterborough, <br />
                  United Kingdom
                </h4> */}
              <h4 className="font-semibold font-raleway">
                Unit 3, Serpentine Green shopping centre <br />
                Peterborough, PE7 8BE
              </h4>
              {/* <h4 className="font-raleway font-semibold">
                  Unit 3 Serpentine Green shopping centre, <br /> PE7 8BE.
                </h4> */}
            </div>
            <div className="md:pl-10 pl-4 mb-4">
              <h4 className="font-raleway font-semibold underline mb-4">
                Term Time
              </h4>
              <div className="flex md:gap-10 gap-6 items-center">
                <div className="flex flex-col gap-2">
                  <h4 className="font-raleway font-semibold">
                    Mon:
                    <span className="font-normal"> Closed</span>
                  </h4>
                  <h4 className="font-raleway font-semibold">
                    Sat: <span className="font-normal"> 10:00 - 20:00</span>
                  </h4>
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="font-raleway font-semibold">
                    Tue - Friday:
                    <span className="font-normal"> 12:00 - 20:00</span>
                  </h4>
                  <h4 className="font-raleway font-semibold">
                    Sun: <span className="font-normal"> 10:00 - 16:00</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="md:pl-10 pl-4">
              <h4 className="font-raleway font-semibold underline mb-4">
                Holiday
              </h4>
              <div className="flex md:gap-10 gap-6 items-center">
                <div className="flex flex-col gap-2">
                  <h4 className="font-raleway font-semibold">
                    Mon - Sat:
                    <span className="font-normal"> 10:00 - 20:00</span>
                  </h4>
                  <h4 className="font-raleway font-semibold">
                    Sun: <span className="font-normal"> 10:00 - 16:00</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={locationImage} alt="" className="md:w-[55%]" />
    </div>
  );
};

export default Location2;
