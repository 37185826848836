import React from "react";
import Header from "../components/Header";
import { partiesHeroBg, planningImage, } from "../assets";
import Events from "../components/Events";
import ClickHere from "../components/ClickHere";
import Planning from "../components/Planning";
import Games from "../components/Games";

const PartiesPage = () => {
  return (
    <div>
      <Header
        hText="Parties & Events."
        pText="Enjoy a hassle free VR Party, with a streamlined experience from start to finish, Our parties are available on all experiences so you can pick which type of party you want to have!"
        imagePicture={partiesHeroBg}
      />
      <ClickHere />
      <Planning
        hText="Are You Planning a Party Or An Event?"
        pText="We take great pride in how we handle each and every special moment of someone’s Birthday, Stag Do, Hen Do, Staff Party and any event that can be considered a party!"
        bText="BOOK NOW"
        imagePicture={planningImage}
      />
      <Games />
      <Events />
    </div>
  );
};

export default PartiesPage;
