import React from "react";
import { colabBg } from "../assets";
import BookGames from "./buttons/BookGames";
import BookParty from "./buttons/BookParty";

const Events = () => {

  const styles2 = {
    backgroundImage: `url(${colabBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  };

  return (
    <div
      style={styles2}
      className="flex flex-col justify-center h-[500px] md:pl-[80px] pl-4 text-white"
    >
      <h1 className="md:leading-tight font-black md:text-6xl text-4xl md:w-[50%] mb-10">
        FOR EVENTS, PARTIES AND COLLABORATION.
      </h1>
      <div className="flex items-center gap-4">
        <BookParty />
        <BookGames />
      </div>
    </div>
  );
};

export default Events;
