import React from "react";

const Header = ({ hText, pText, imagePicture, cs }) => {
  const styles = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)), url(${imagePicture})`,
    // backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6)), url(${sliderImages[current].imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  };

  return (
    <div
      style={styles}
      className="h-[60vh] bg-black flex flex-col justify-center text-white md:pl-[80px] px-4 pt-12 mt-12"
    >
      {cs && (
        <p className="font-semibold font-raleway md:text-base text-sm md:mb-0 mb-2">
          Coming soon
        </p>
      )}
      <h1 className="font-black md:text-6xl text-4xl md:w-[45%] md:leading-tight mb-6 ">
        {hText}
      </h1>
      <p className="font-black font-raleway md:w-[45%]">{pText}</p>
    </div>
  );
};

export default Header;
