import React from "react";
import { foodsHeroBg } from "../assets";
import Header from "../components/Header";
import Events from "../components/Events";
import ClickHere from "../components/ClickHere";
import Foods from "../components/Foods";

const FoodsPage = () => {
  return (
    <div>
      <Header
        hText="Foods & Drinks."
        pText="From snacks to drinks and everything in between, we've got you covered. Add Refreshment to your Booking placement during checkout."
        imagePicture={foodsHeroBg}
        cs={true}
      />
      <ClickHere />
      <Foods />
      <Events />
    </div>
  );
};

export default FoodsPage;
